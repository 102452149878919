<template>
  <div>
    <!-- 顶部搜索框 -->
    <!-- 左侧搜索 -->
    <div class="display_flex top">
      <div class="search-top">
        <el-form :inline="true" :model="formData" class="demo-form-inline">
          <el-form-item>
            <el-input class="w-208" @input="search" v-model="formData.searchs" @keydown.enter="search" clearable
              placeholder="名称/拼音">
              <template #suffix>
                <div @click="search" class="searchcss">
                  <i class="fa fa-search"></i>
                </div>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item style="margin-left: 30px">
            <div class="display_flex" style="color: #3166ae">
              <el-tooltip placement="bottom">
                <template #content>
                  <div style="width: 160px">
                    库存偏差=盘点数量-库存数量
                  </div>
                </template>
                <div class="faicon">
                  <i class="fa fa-question-circle-o icou1"></i>
                </div>
              </el-tooltip>
              <div>库存偏差</div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class="item">
        <el-button class="color-vice" type="primary" @click="exportTable">
          <i class="fa fa-arrow-down"></i>导出
        </el-button>
      </div>
    </div>
    <!-- 右侧按钮 -->


    <!-- 表格内容 -->
    <div>
      <el-table class="sss" v-loading="loading" ref="multipleTableRef" :data="tableData" size="small" style="width: 100%" stripe
        row-key="id" border @expand-change="getChildrens">
        <el-table-column type="expand">
          <template #default="props">
            <div style="padding:10px; background: #fafafa;">
              <el-tabs type="border-card">
                <el-tab-pane label="入库">
                  <el-table class="sss" :data="props.row.ruleItemData" size="small" stripe border style="width:100%;">
                    <el-table-column label="类型" width="80" prop="enter_type" align="center">
                      <template #default="scope">
                        <el-tag effect="dark" type="success">入库</el-tag>
                      </template>
                    </el-table-column>
                    <el-table-column label="批号" width="120" prop="batch_no" />
                    <el-table-column label="采购单价" width="100" prop="buy_price" />
                    <el-table-column label="零售单价" width="100" prop="price" />
                    <el-table-column label="入库" width="120" prop="enter_num">
                      <template #default="scope">
                        <span>
                          {{ scope.row.enter_num }} {{ scope.row.drug.min_package_unit_name }}
                          <template v-if="scope.row.enter_sub_num">{{ scope.row.enter_sub_num }} {{
                            scope.row.drug.min_unit_name }}</template>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column label="有效期" width="120" align="center" prop="validity_end_time" />
                    <el-table-column label="供货厂家" width="180" show-overflow-tooltip prop="supplier" />
                    <el-table-column label="状态" width="80" align="center">
                      <template #default="scope">
                        <el-tag type="danger" v-if="scope.row.is_return == 1">撤销</el-tag>
                        <el-tag type="success" v-else>正常</el-tag>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作人" width="100" prop="operator.name" />
                    <el-table-column label="入库时间" width="180" prop="enter_time" />
                  </el-table>
                  <div class="pagination-box">
                    <el-pagination v-model:currentPage="pageDataRK.currentPage" v-model:page-size="pageDataRK.pageSize"
                      :page-sizes="[10, 20, 50, 100]" :background="true"
                      layout="total, sizes, prev, pager, next, jumper" :total="pageDataRK.totalSum"
                      @size-change="handleSizeChangeRK" @current-change="handleCurrentChangeRK"></el-pagination>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="出库">
                  <el-table class="sss" :data="props.row.out_record_list" size="small" stripe border style="width:100%;">
                    <el-table-column label="类型" width="80" prop="enter_type" align="center">
                      <template #default="scope">
                        <el-tag effect="dark" type="danger">出库</el-tag>
                      </template>
                    </el-table-column>
                    <el-table-column label="批号" width="120" prop="batch_no" />
                    <el-table-column label="出库" width="120" prop="out_num">
                      <template #default="scope">
                        <span>
                          {{ scope.row.out_num }} {{ scope.row.drug.min_package_unit_name }}
                          <template v-if="scope.row.out_sub_num">{{ scope.row.out_sub_num }} {{
                            scope.row.drug.min_unit_name }}</template>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column label="出库对象" width="120" prop="toUser.name" />
                    <el-table-column label="出库类型" align="center" width="120">
                      <template #default="scope">
                        <el-tag type="danger" size="mini" v-if="scope.row.dict_out_type == '205130000'">{{
                          scope.row.dict_out_type_name }}</el-tag>
                        <el-tag type="success" size="mini" v-if="scope.row.dict_out_type == '205120000'">{{
                          scope.row.dict_out_type_name }}</el-tag>
                        <el-tag type="info" size="mini" v-if="scope.row.dict_out_type == '205110000'">{{
                          scope.row.dict_out_type_name }}</el-tag>
                        <el-tag type="warning" size="mini" v-if="scope.row.dict_out_type == '205140000'">{{
                          scope.row.dict_out_type_name }}</el-tag>
                      </template>
                    </el-table-column>
                    <el-table-column label="出库时间" width="200" prop="out_time" />
                    <el-table-column label="出库人" width="120" prop="operator.name" />
                    <el-table-column label="出库来源" width="100" align="center">
                      <template #default="scope">
                        <el-tag type="success" size="mini" v-if="scope.row.dict_out_source == '905110000'">{{
                          scope.row.dict_out_source_name }}</el-tag>
                        <el-tag type="warning" size="mini" v-else>{{ scope.row.dict_out_source_name }}</el-tag>
                      </template>
                    </el-table-column>
                  </el-table>
                  <div class="pagination-box">
                    <el-pagination v-model:currentPage="pageDataCK.currentPage" v-model:page-size="pageDataCK.pageSize"
                      :page-sizes="[10, 20, 50, 100]" :background="true"
                      layout="total, sizes, prev, pager, next, jumper" :total="pageDataCK.totalSum"
                      @size-change="handleSizeChangeCK" @current-change="handleCurrentChangeCK"></el-pagination>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="200" prop="name" label="名称" show-overflow-tooltip>
          <template #default="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="medi_spec" label="规格" show-overflow-tooltip />
        <el-table-column width="120" property="approval_no" label="国药准字" show-overflow-tooltip />
        <el-table-column width="180" property="company" label="厂家" show-overflow-tooltip />
        <el-table-column width="120" label="入库数量">
          <template #default="scope">
            {{ scope.row.total_enter_num }} {{ scope.row.min_package_unit_name }}
            <template v-if="scope.row.total_enter_sub_num">{{ scope.row.total_enter_sub_num }} {{
              scope.row.min_unit_name }}</template>
          </template>
        </el-table-column>
        <el-table-column width="120" label="出库数量">
          <template #default="scope">
            {{ scope.row.total_out_num }} {{ scope.row.min_package_unit_name }}
            <template v-if="scope.row.total_out_sub_num">{{ scope.row.total_out_sub_num }} {{
              scope.row.min_unit_name }}</template>
          </template>
        </el-table-column>
        <el-table-column label="库存数量" width="120" align="center">
          <template #default="scope">
            <el-tag effect="dark">
              {{ scope.row.store_num }} {{ scope.row.min_package_unit_name }}
              <template v-if="scope.row.store_sub_num">{{ scope.row.store_sub_num }} {{
                scope.row.min_unit_name }}</template>
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="盘点数量" width="120" align="center">
          <template #default="scope">
            <el-tag effect="dark">
              {{ scope.row.check_num }} {{ scope.row.min_package_unit_name }}
              <template v-if="scope.row.check_sub_num">{{ scope.row.check_sub_num }} {{
                scope.row.min_unit_name }}</template>
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="库存偏差" width="120" align="center">
          <template #default="scope">
            <el-tag effect="dark">
              {{ scope.row.diff_num }} {{ scope.row.min_package_unit_name }}
              <template v-if="scope.row.diff_sub_num">{{ scope.row.diff_sub_num }} {{
                scope.row.min_unit_name }}</template>
            </el-tag>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-box">
        <el-pagination v-model:currentPage="pageData.currentPage" v-model:page-size="pageData.pageSize"
          :page-sizes="[10, 20, 50, 100]" :background="true" layout="total, sizes, prev, pager, next, jumper"
          :total="pageData.totalSum" @size-change="handleSizeChange"
          @current-change="handleCurrentChange"></el-pagination>
      </div>
    </div>

    <BaseDialog title="入库" :isshow="createEnterVisible" width="900px" height="auto">
      <div style="margin-top:10px;">
        <el-descriptions :column="3" size="small" border>
          <el-descriptions-item label="药品名称">{{ drug.name }}</el-descriptions-item>
          <el-descriptions-item label="规格">{{ drug.medi_spec }}</el-descriptions-item>
          <el-descriptions-item label="类别">{{ drug.fee_type }}</el-descriptions-item>
          <el-descriptions-item label="生产厂家">{{ drug.company }}</el-descriptions-item>
        </el-descriptions>

        <el-form :model="enterForm" label-width="120px" style="margin-top:10px;">
          <el-row>
            <el-col :span="12">
              <el-form-item label="批号">
                <el-input v-model="enterForm.batch_no" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item label="进货单位">
                <el-input disabled v-model="drug.min_package_unit_name" />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="进价">
                <el-input v-model="enterForm.buy_price" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <el-form-item label="零售单位">
                <el-input disabled v-model="drug.min_unit_name" />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="零售价">
                <el-input v-model="enterForm.price" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-alert type="info" :closable="false" style="margin-bottom:10px;">
                <p>换算系数 {{ drug.min_package_num }} 进货单位=零售单位 * 系数</p>
              </el-alert>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="入库数量">
                <el-col :span="11">
                  <el-input v-model="enterForm.enter_num" placeholder="">
                    <template #append>{{ drug.min_package_unit_name }}</template>
                  </el-input>
                </el-col>
                <el-col :span="2"></el-col>
                <el-col :span="11">
                  <el-input v-model="enterForm.enter_sub_num" placeholder="">
                    <template #append>{{ drug.min_unit_name }}</template>
                  </el-input>
                </el-col>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="有效期">
                <el-date-picker v-model="enterForm.validity_end_time" value-format="YYYY-MM-DD" type="date"
                  placeholder="有效期至" style="width: 100%" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="供应商">
                <el-input v-model="enterForm.supplier" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="备注">
                <el-input v-model="enterForm.remark" type="textarea" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item>
            <el-button type="primary" @click="onSubmitEnter">提交</el-button>
            <el-button @click="onCancelEnter">撤销</el-button>
          </el-form-item>
        </el-form>
      </div>
    </BaseDialog>
  </div>
</template>

<script>
  import { toRefs, reactive, onMounted } from 'vue'
  import service from '@/utils/request'
  import { gitDictList, TimeYear, downLoadXlsx, TimestampToTime } from '@/utils/tool'
  import BaseDialog from '@/components/Dialog/index.vue'
  export default {
    components: {
      BaseDialog
    },
    setup() {
      const state = reactive({
        formData: {
          searchs: '',
          dict_type: ''
        },
        loading: true,
        pageData: {
          currentPage: 1,
          pageSize: 10,
          totalSum: 0,
        },
        pageDataCK: {
          currentPage: 1,
          pageSize: 10,
          totalSum: 0,
        },
        pageDataRK: {
          currentPage: 1,
          pageSize: 10,
          totalSum: 0,
        },
        tableData: [],
        dictList: {},
        createEnterVisible: false,
        drug: {},
        enterForm: {
          batch_no: '',
          buy_price: '',
          price: '',
          enter_num: '',
          enter_sub_num: '',
          validity_end_time: '',
          supplier: '',
          remark: ''
        },
        enter_record_list: []
      })
      onMounted(() => {
        initData()
        tobody()
      })
      // 获取字典
      const initData = async () => {
        state.dictList = await gitDictList([
          '332900000',
          '210000000',
          '103000000',
          '160000000',
          '116000000',
          '176000000',
          '231000000',
          '157000000',
          '158000000',
          '159000000',
          '339600000',
          '164000000',
          '113000000',
          '161000000',
          '100000000',
          '340200000',
          '339600000',
        ])
      }
      // 获取列表
      const tobody = async () => {
        state.loading = true
        let data = {
          size: state.pageData.pageSize,
          current: state.pageData.currentPage,
          name: state.formData.searchs,
          dict_type: state.formData.dict_type,
          dict_catalog_type: '160000000'
        }
        let res = await service.post('/api/drug/store_list', data)
        if (res.code === 0) {
          if (res.data.total > 0) {
            res.data.records.forEach(item => {
              item.ruleItemData = []
            })//给每行数据强制追加一个数据项
            state.tableData = res.data.records
            state.pageData.totalSum = res.data.total
          } else {
            state.tableData = []
            state.pageData.totalSum = 0
          }
        }
        state.loading = false
      }
      // 条数改变时
      const handleSizeChange = (e) => {
        state.pageData.pageSize = e
        tobody()
      }
      // 页数改变时
      const handleCurrentChange = (e) => {
        state.pageData.currentPage = e
        tobody()
      }
      // 出库条数改变时
      const handleSizeChangeCK = (e) => {
        state.pageDataCK.pageSize = e
        tobodyCK()
      }
      // 入库页数改变时
      const handleCurrentChangeRK = (e) => {
        state.pageDataRK.currentPage = e
        tobodyRK()
      }
      // 入库条数改变时
      const handleSizeChangeRK = (e) => {
        state.pageDataRK.pageSize = e
        tobodyRK()
      }
      // 入库页数改变时
      const handleCurrentChangeCK = (e) => {
        state.pageDataCK.currentPage = e
        tobodyCK()
      }
      const type = () => {
        tobody()
      }
      // 查询
      const search = () => {
        tobody()
      }
      // 入库点击
      const enterClick = (e) => {
        getDrugInfo(e.id)
        state.createEnterVisible = true
      }
      // 获取药品信息
      const getDrugInfo = async (drugId) => {
        let data = {
          drug_id: drugId
        }
        let res = await service.post('/api/drug/info', data)
        if (res.code === 0) {
          state.drug = res.data.drug
          console.log(state.drug, 12)
        }
      }
      // 提交入库
      const onSubmitEnter = async () => {
        console.log(state.enterForm)
      }
      // 取消
      const onCancelEnter = () => {
        state.createEnterVisible = false
        initEnterForm()
      }
      // 初始化入库
      const initEnterForm = () => {
        state.enterForm = {
          batch_no: '',
          buy_price: '',
          price: '',
          enter_num: '',
          enter_sub_num: '',
          validity_end_time: '',
          supplier: '',
          remark: '',
        }
      }
      // 导出
      const exportTable = (row) => {
        let data = {
          name: state.formData.searchs,
          dict_type: state.formData.dict_type,
          dict_catalog_type: '160000000'
        }
        let time = TimestampToTime()
        let title = `科室药品库存${time}.xlsx`
        downLoadXlsx('/api/drug/store_export', data, title)
      }
      const getChildrens = (row, code) => {
        if (code > 0) {
          //code>0时 才发起请求
          // 遍历当前页面表
          state.tableData.forEach((temp, index) => {
            // 找到当前点击的行，把动态获取到的数据赋值进去
            if (temp.id === row.id) {
              state.tableData[index].ruleItemData = res.content;
            }
          });
        }
      }
      // 获取列表
      const tobodyCK = async () => {
        state.loading = true
        let data = {
          size: state.pageData.pageSize,
          current: state.pageData.currentPage,
          name: state.formData.searchs,
          dict_type: state.formData.dict_type,
          dict_catalog_type: '160000000'
        }
        let res = await service.post('/api/drug/store_list', data)
        if (res.code === 0) {
          if (res.data.total > 0) {
            state.tableData = res.data.records
            state.pageData.totalSum = res.data.total
          } else {
            state.tableData = []
            state.pageData.totalSum = 0
          }
        }
        state.loading = false
      }
      // 获取列表
      const tobodyRK = async () => {
        state.loading = true
        let data = {
          size: state.pageData.pageSize,
          current: state.pageData.currentPage,
          name: state.formData.searchs,
          dict_type: state.formData.dict_type,
          dict_catalog_type: '160000000'
        }
        let res = await service.post('/api/drug/store_list', data)
        if (res.code === 0) {
          if (res.data.total > 0) {
            state.tableData = res.data.records
            state.pageData.totalSum = res.data.total
          } else {
            state.tableData = []
            state.pageData.totalSum = 0
          }
        }
        state.loading = false
      }
      return {
        ...toRefs(state),
        tobody,
        tobodyCK,
        tobodyRK,
        enterClick,
        getDrugInfo,
        handleSizeChange,
        handleCurrentChange,
        handleSizeChangeCK,
        handleCurrentChangeCK,
        handleSizeChangeRK,
        handleCurrentChangeRK,
        onSubmitEnter,
        onCancelEnter,
        initEnterForm,
        search,
        type,
        exportTable,
        getChildrens
      }
    },
  }
</script>

<style scoped lang="scss">
  .displya_flex {
    display: flex;
    justify-content: space-between;
  }

  .usertop {
    margin-bottom: 8px;
    height: 35px;
    display: flex;
    justify-content: space-between;

    &:deep(.el-alert--warning.is-light) {
      background-color: rgba(49, 102, 174, 0.1);
      color: #3166ae;
    }
  }

  .tabctext {
    height: 20px;
    overflow: hidden;
    cursor: pointer;
    color: rgba(49, 102, 174, 1);
  }

  .display_flex {
    display: flex;
    justify-content: space-between;
  }

  .search-top .w-208 {
    width: 208px;

    .searchcss {
      cursor: pointer;
    }
  }

  .labelSet1 {
    width: 60px;
    height: 30px;
    line-height: 30px;
    display: block;
    text-align: center;
    color: #fff;
    border-radius: 5px;
  }

  .psckset {
    background-color: #409EFF;
  }

  .lcsyset {
    background-color: #c6f1b1;
    color: #3bb207;
    border: 1px solid #5bd324;
  }
</style>